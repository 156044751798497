import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/components';
import Pagination from '../components/Pagination';
import styled from 'styled-components';
import usePosition from '../components/usePosition';

const CustomSection = styled(Section)`
  .work-tabs {
    max-width: 800px;
  }
`;

const Work = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allAppsMobileGamesJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
      allVrGamesJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
      allWebJson {
        edges {
          node {
            slug
            category
            titleForButton
            technologies
            projectName
            viewOrder
            listIntro {
              image
              altText
              title
              description
            }
          }
        }
      }
    }
  `);
  usePosition();
  const projects = [
    ...data.allWebJson.edges,
    ...data.allVrGamesJson.edges,
    ...data.allAppsMobileGamesJson.edges,
  ].sort(function (a, b) {
    return a.node.viewOrder - b.node.viewOrder;
  });
  return (
    <Layout navbarBackground="rgba(255, 255, 255, 0.8)" navbarColor="#3C4356">
      <SEO title="Projects" />
      <CustomSection
        padding="134px 0px 31px 0px"
        tabletPadding="134px 0px 178px  0px"
        mobilePadding="134px 0px 144px 0px"
        className="position-relative overflow-hidden"
      >
        <Pagination data={projects} size={5} />
      </CustomSection>
    </Layout>
  );
};

export default Work;
